<template>
  <div class="login" @keydown.enter="login()">
    <div class="middle">
      <!-- <h1 class="header">
                <img src="../assets/img/login_logo.png" width="64" height="64"/>
                城市级停车管理平台
            </h1> -->
      <div class="wrapper">
        <div class="content">
          <div class="title">
            找回密码
          </div>
          <div class="loginForm">
            <input type="password" style="display: none" />
            <el-form ref="loginForm" :model="formInline" :rules="rules" label-width="80px" label-position="top">
              <el-form-item :label="$t('searchModule.phone_number')" :class="{ 'is-error': userErr }" prop="mobile">
                <el-input
                  v-model.trim="formInline.mobile"
                  :maxlength="11"
                  @change="userErr = false"
                  placeholder="请输入手机号"
                ></el-input>
                <i class="iconfont icon-yonghu" style="position: absolute; right: 0; top: 0; color: #B0BAC5;"></i>
              </el-form-item>
              <el-form-item label="短信验证码" :class="{ 'is-error': codeErr }" prop="validationCode">
                <el-input
                  placeholder="输入短信验证码"
                  :maxlength="6"
                  v-model.trim="formInline.checkCode"
                  autocomplete="off"
                  @change="codeErr = false"
                  style="width: 180px;"
                ></el-input>
                <div class="inline-block checkCode">
                  <!-- <el-button type="primary" :loading="loading" @click="getCode()" style="width:100%;" :disabled="sendCk">{{ -->
                  <el-button type="primary" :loading="loading" @click="beforeGetSmsVeifyCode()" style="width:100%;" :disabled="sendCk">{{
                    showT
                  }}</el-button>
                </div>
              </el-form-item>
              <el-form-item label="新密码" :class="{ 'is-error': userErr }" prop="password">
                <el-input
                  placeholder="请输入新密码"
                  v-model.trim="formInline.password"
                  autocomplete="new-password"
                  type="password"
                  @change="userErr = false"
                ></el-input>
                <i class="iconfont icon-mima" style="position: absolute; right: 0; top: 0; color: #B0BAC5;"></i>
              </el-form-item>
              <el-form-item label="确认密码" :class="{ 'is-error': userErr }" prop="newPassword">
                <el-input
                  autocomplete="new-password"
                  placeholder="请再次输入密码"
                  v-model.trim="formInline.newPassword"
                  type="password"
                  @change="userErr = false"
                ></el-input>
                <i class="iconfont icon-mima" style="position: absolute; right: 0; top: 0; color: #B0BAC5;"></i>
              </el-form-item>
            </el-form>
            <span class="errorMsg" v-show="showMsg">{{ errorMsg }}</span>
            <el-button
              type="primary"
              :disabled="allowClick"
              :loading="loading"
              @click="login"
              style="width: 100%;margin-top: 15px;"
              >提交</el-button
            >
            <el-button type="primary" :loading="loading" @click="$router.push('/')" style="width: 100%;margin:15px 0 0 0;"
              >返回登录页</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      width="380px"
      :append-to-body="true"
      title="输入验证码"
      :close-on-click-modal="false"
      :visible.sync="graphVeifyCodeShow"
    >
      <el-form
        :model="formInline"
        label-position="right"
        style="margin-left: 20px; margin-right: 30px"
      >
        <el-form-item label="" class="graphcode">
          <el-input
            v-model.trim="formInline.graphVeifyCode"
            placeholder="请输入右图中的验证码"
            @change="codeErr = false"
            style="width: 180px"
          ></el-input>
          <div
            class="inline-block"
            @click="changeResetCode"
            style="margin-left: 10px"
          >
            <img :src="codeImg" width="150%" height="150%" />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="verifyGraphCode">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { jsMd5 } from "@/common/js/public.js";
// import jsMd5 from 'js-md5'
export default {
  name: "login",
  data() {
    let checkPwd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入密码"));
      // } else if (/^[\w|@]{6,15}$/.test(value)) {
      } else if (/(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}/.test(value)) {
        callback();
      } else {
        // return callback(new Error("密码只能是数字、字母、特殊字符或下划线且长度在6-15位"));
        return callback(new Error("大写字母+小写字母+数字+特殊字符，至少8位"));
      }
    };
    let checkPwdAgain = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请再次输入密码"));
      } else {
        if (this.formInline.password == this.formInline.newPassword) {
          callback();
        } else {
          callback(new Error("两次输入的密码不一致"));
        }
      }
    };
    let checkCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };

    let checkMobile = (rule, value, callback) => {
      if (!value) {
        this.sendCk = true;
        return callback(new Error("手机号不能为空"));
      } else {
        if (value.length < 11) {
          this.sendCk = true;
          callback();
        } else {
          this.sendCk = false;
          callback();
        }
      }
    };
    return {
      formInline: {
        mobile: "",
        password: "",
        newPassword: "",
        checkCode: "",
        graphVeifyCode: "",
        verifyCodeId: new Date().getTime(),
      },
      graphVeifyCodeShow: false,
      codeImg: "",
      account: "",
      showT: "发送验证码",
      showMsg: false,
      sendCk: true,
      checkTime: 0,
      errorMsg: "",
      codeErr: false,
      userErr: false,
      loading: false,
      rules: {
        mobile: [{ validator: checkMobile, trigger: "change" }],
        newPassword: [{ validator: checkPwdAgain, trigger: "blur" }],
        password: [{ validator: checkPwd, trigger: "blur" }],
        checkCode: [{ validator: checkCode, trigger: "blur" }]
      }
    };
  },
  methods: {
    login() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          this.$axios
            .post("/acb/2.0/systems/login/findPassword", {
              data: {
                mobile: this.formInline.mobile,
                password: jsMd5(this.account, this.formInline.newPassword),
                verifyCode: this.formInline.checkCode
              }
            })
            .then(res => {
              this.$router.push("/");
            })
            .catch(e => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },

    beforeGetSmsVeifyCode() {
      this.graphVeifyCodeShow = true;
      this.formInline.graphVeifyCode = "";
      this.changeResetCode();
    },

    changeResetCode() {
      if (!this.formInline.verifyCodeId) {
        this.formInline.verifyCodeId = new Date().getTime();
      }
      this.$axios
        .get("/acb/2.0/systems/login/verificationCode", {
          data: {
            verifyCodeId: this.formInline.verifyCodeId,
          },
        })
        .then((res) => {
          this.codeImg = "data:image/gif;base64," + res.value;
        });
    },

    verifyGraphCode() {
      this.$axios
        .post("/acb/2.0/systems/login/verifyCode", {
          data: {
            verifyCodeId: this.formInline.verifyCodeId,
            verifyCode: this.formInline.graphVeifyCode,
          },
        })
        .then(
          (res) => {
            this.graphVeifyCodeShow = false;
            this.getCode();
          },
          (res) => {}
        );
    },
    getCode() {
      this.$axios
        .get("/acb/2.0/systems/login/createPasswordCheckCodeNoLogin", {
          data: {
            mobile: this.formInline.mobile
          }
        })
        .then(res => {
          if (res.state == 0) {
            this.account = res.value;
            let seconds = 60;
            this.sendCk = true;
            let timer = setInterval(() => {
              this.showT = seconds-- + "s";
              if (seconds == -1) {
                this.showT = "发送验证码";
                this.sendCk = false;
                clearInterval(timer);
              }
            }, 1000);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        });
    }
  },
  created() {
    //	sessionStorage.clear();
  },
  computed: {
    allowClick() {
      if (
        this.formInline.mobile &&
        this.formInline.mobile.length == 11 &&
        this.formInline.newPassword == this.formInline.password &&
        this.formInline.newPassword &&
        this.formInline.newPassword.length <= 15 &&
        this.formInline.password.length >= 6 &&
        this.formInline.checkCode &&
        this.formInline.checkCode.length == 6
      ) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.graphcode {
  >>>.el-form-item__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 0;
  }
}
.find-psd
  text-align:right;
  padding-top:10px;
.login
  background: url("../../assets/img/login_bg.png") no-repeat center/cover;
  height: 100%;
  width: 100%;
  .middle
    position: relative;
    margin: 0 auto;
    padding-top: 100px;
    .header
      font-family: MicrosoftYaHei-Bold;
      font-size: 38px;
      color: #D8E2FF;
      letter-spacing: 3.62px;
      text-align: center;
      img
        vertical-align: middle;
        margin-top: -5px;
    .content
      width: 380px;
      background: #F9FAFC;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.20);
      border-radius: 4px;
      margin: 20px auto 0;
      .title
        height: 60px;
        line-height: 60px;
        padding-left: 30px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        color: #FFFFFF;
        letter-spacing: 0;
        background-image: linear-gradient(-179deg, #0099FA 0%, #0085DA 100%);
        border-radius: 4px 4px 0 0;
      .loginForm
        padding: 30px;
        position: relative;
        .checkCode
          width: 116px;
          float: right;
          text-align: center;
          overflow: hidden;
        .errorMsg
          position: absolute;
          font-size: 12px;
          color: #FF4949;
          bottom: 77px;
</style>
<style>
.el-form-item__label {
  line-height: 16px;
  padding: 11px 12px 11px 0;
}
</style>
